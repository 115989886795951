import React from 'react'
import { PricingWrapper, PricingWrapperSecondary } from './pricing-styles'
import SinglePricing from './single-pricing'
import { Container } from '../../common/common-styles'

function Pricing({ pricingContent }) {
  const rawPricingData = pricingContent
  const keysArr = Object.keys(rawPricingData)

  if (keysArr.length < 3)
    return (
      <Container width="1200px" id="pricing">
        <PricingWrapperSecondary gridSize={keysArr.length}>
          {keysArr.map(key => (
            <SinglePricing
              key={key}
              data={pricingContent[key]}
              main={pricingContent[key].featured}
            />
          ))}
        </PricingWrapperSecondary>
      </Container>
    )

  return (
    <Container width="1200px" id="pricing">
      <PricingWrapper>
        {keysArr.slice(0, 3).map(key => (
          <SinglePricing
            key={key}
            data={pricingContent[key]}
            main={pricingContent[key].featured}
          />
        ))}
      </PricingWrapper>
      <PricingWrapperSecondary gridSize={keysArr.length - 3}>
        {keysArr.slice(3).map(key => (
          <SinglePricing
            key={key}
            data={pricingContent[key]}
            main={pricingContent[key].featured}
          />
        ))}
      </PricingWrapperSecondary>
    </Container>
  )
}

export default Pricing
