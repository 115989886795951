import React from 'react'
import { NotFoundWrapper } from './404-styles'
import notFoundSVG from '../../images/illustrations/404.svg'
import { Container } from '../common/common-styles'
function NotFoundPage() {
  return (
    <NotFoundWrapper>
      <Container>
        <h1>404! You've Hit a Dead End</h1>
        <img src={notFoundSVG} alt="404 Page not Found" />
      </Container>
    </NotFoundWrapper>
  )
}

export default NotFoundPage
