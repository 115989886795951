import React, { useContext } from 'react'
import { Link } from 'gatsby'

import { GlobalCurrencyContext } from '../../../context/global-context'
import { SinglePricingWrapper } from './pricing-styles'
import icon from '../../../images/icons/icons.svg'
import Button from '../../common/cta-button/button'

function SinglePricing({ data, main }) {
  const { key, curr } = useContext(GlobalCurrencyContext)

  const {
    title,
    discountPercent,
    featureLabel,
    features,
    buttonLabel,
    buttonURL,
    footerText
  } = data

  return (
    <SinglePricingWrapper main={main}>
      <div className="price-header">
        <span className="feature-label">{featureLabel}</span>
        <h2>{title}</h2>
        <div className="price">
          <span>
            {curr}
            {Math.ceil(data[key] * (100 / discountPercent))}
          </span>
          <div className="main-price">
            <span>{curr}</span>
            <span>{data[key]}</span>
          </div>
          <span>/mo.</span>
        </div>
      </div>

      <div className="price-body">
        <ul>
          {features.map(feature => (
            <li key={feature}>
              <svg width="16" height="20" viewBox="0 0 16 20">
                <use href={`${icon}#check`} />
              </svg>{' '}
              {feature}
            </li>
          ))}
        </ul>
        <Button
          size="big"
          hideArrow
          as={Link}
          href={buttonURL}
          to={buttonURL}
          secondary={!main}
          hover={!main}
          disabled={buttonURL.length < 5}
        >
          {buttonLabel}
        </Button>
        {main && footerText && <p>{footerText}</p>}
      </div>
    </SinglePricingWrapper>
  )
}

export default SinglePricing
