import styled from 'styled-components'
import media from '../../utils/breakpoints'
import dualDots from '../../../images/illustrations/dual-dots.svg'

export const PricingWrapper = styled.div`
  display: block;
  grid-template-columns: repeat(10, minmax(0, 1fr));
  grid-template-rows: 50px 1fr 80px;
  position: relative;
  padding: 1.5rem 0;
  /* background: url("data:image/svg+xml,%3Csvg width='944' height='819' viewBox='0 0 944 819' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M23.9974 144.5C119.997 87.0004 100.359 83.8329 59.0258 114.5C27.6924 139.833 -21.7742 186.9 31.0258 172.5C97.0258 154.5 78.5253 149 156.525 93C218.925 48.2 233.192 74.3334 232.525 93C163.192 280.333 18.4979 702.003 3.99744 776.5C-12.6483 862.018 65.6759 787.25 69.9974 776.5C77.3824 758.13 211.025 630.5 183.025 688.5C160.626 734.9 150.001 698.508 150.001 698.508C150.001 698.508 94.2216 817.1 139.022 807.5C183.822 797.9 240.359 732.5 263.025 704.5L613.051 144.5C613.051 123.167 609.425 -10.3004 643.025 2.49958C685.025 18.4996 781 199 789 219C797 239 777.025 680.5 789.025 746.5C801.025 812.5 826.832 815.854 844.5 781.974C863 746.5 878.975 317.333 892.975 97.9995C892.975 47.9996 899 -2.00043 859 25.9996C819 53.9996 741.949 155 771.949 183C801.949 211 939.949 109 941.949 61' stroke='%23FD2D55' stroke-width='3'/%3E%3C/svg%3E%0A")
    top center / contain no-repeat;
  background: url("data:image/svg+xml,%3Csvg width='1028' height='877' viewBox='0 0 1028 877' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M55.0003 45C33.0003 33 -0.999717 113 3.00028 139C5.0002 335.667 7.80204 691.8 3.00224 707C-2.99752 726 12.002 835 89.002 829C166.002 823 141.002 707 219.002 716C297.002 725 229.002 867 291.002 875C353.002 883 419.002 774 500.002 789C581.002 804 580.002 870 656.002 862C732.002 854 715.002 732 782.002 707C849.002 682 878.002 778 927.002 819C976.002 860 1010.2 773.112 1012 716C1013.48 669.374 1043 56 1013 55C983 54 969.602 129 932.002 145C885.002 165 863.002 115 817.002 72C771.002 29 740.002 98 728.002 115C716.002 132 666.002 135 646.002 115C626.002 95 614.002 41 562.002 10C510.002 -21 450.002 51 402.002 81C360.002 107 323.002 67 291.002 72C259.002 77 231.002 156 185.002 169C139.002 182 115.002 159 100.002 135C85.002 111 77.0003 57 55.0003 45Z' stroke='%23FD2D55' stroke-width='3'/%3E%3C/svg%3E%0A")
    top center / contain no-repeat; */
  background: url(${dualDots}) top center / contain no-repeat;

  background-size: 100% 100%;
  & > div:first-child {
    grid-column: 1 / 4;
    grid-row: 2 / 3;
    max-width: 380px;
  }
  & > div:nth-child(2) {
    grid-column: 4 / 8;
    grid-row: 1 / 4;
    max-width: 500px;
  }
  & > div:nth-child(3) {
    grid-column: 8 / 11;
    grid-row: 2 / 3;
    max-width: 380px;
  }

  ${media.md} {
    display: grid;
    padding: 3rem 0;
    & > div:first-child {
      border-radius: 10px 0 0 10px;
      border-right: none;
    }
    & > div:nth-child(3) {
      border-radius: 0 10px 10px 0;
      border-left: none;
    }
  }
`

export const PricingWrapperSecondary = styled.div`
  display: block;
  grid-template-columns: ${({ gridSize }) =>
    gridSize && `repeat(${gridSize}, minmax(0, 1fr));`};
  gap: 1rem;
  position: relative;
  padding: 1.5rem 0;
  background: url(${dualDots}) top center / contain no-repeat;
  background-size: 100% 100%;
  & > div {
    max-width: 380px;
  }

  ${media.md} {
    display: grid;
    padding: 3rem 0;
  }
`

export const SinglePricingWrapper = styled.div`
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin: 2rem auto;
  width: 100%;
  overflow: hidden;
  background: #f9fafb;
  border: solid 2px var(--grey);
  span.feature-label {
    display: none;
    text-align: center;
    width: fit-content;
    font-size: 0.8rem;
    line-height: 1.2rem;
    text-transform: uppercase;
    border-radius: 50px;
    padding: 0.2rem 1rem;
    background: var(--blue);
    color: var(--cool-white);
    letter-spacing: 1.5px;
    font-weight: bold;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
  div.price-header {
    padding: 3rem 1.5rem 2.5rem;
    background: #fff;
    position: relative;
    h2 {
      text-align: center;
    }
    div.price {
      font-family: jost;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--grey);
      padding-top: 1rem;
      span:first-child,
      span:last-child {
        font-size: 1.5rem;
      }
      & > span:first-child {
        position: relative;
        &:before {
          content: '';
          position: absolute;
          top: 45%;
          bottom: 45%;
          border-radius: 1px;
          left: 0;
          right: 0;
          background: var(--grey);
        }
      }
      div.main-price {
        display: flex;
        align-items: flex-start;
        padding: 1rem 0.5rem;
        color: var(--black);
        span:first-child {
          font-size: 2rem;
        }
        span:last-child {
          font-size: 3.5rem;
        }
      }
    }
  }
  div.price-body {
    padding: 40px;
    ul {
      list-style: none;
      li {
        display: flex;
        align-items: flex-start;
        margin: 0.5rem 0;
        svg {
          display: block;
          margin-top: 10px;
          margin-right: 10px;
        }
      }
    }
    a {
      width: 100%;
      text-align: center;
      margin: 1rem 0;
    }
    p {
      text-align: center;
      color: var(--grey);
    }
  }

  ${({ main }) =>
    main &&
    `
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  border: 3px solid var(--blue);
  overflow: visible;
  span.feature-label {
    display: block;
  }
  div.price-header {
    border-radius: 10px 10px 0 0;
    div.price {
      div.main-price {
        span:first-child {
          font-size: 3rem;
        }
        span:last-child {
          font-size: 4.5rem;
        }
      }
    }
  }
  div.price-body {
    border-radius: 0 0 10px 10px;
    a {
      font-size: 1.5rem;
      padding: 0.7rem;
      margin: 3rem 0 2rem;
    }
  }
    `}

  ${media.md} {
    margin: 0 auto;
  }
`
