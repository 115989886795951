import styled from 'styled-components'

export const NotFoundWrapper = styled.section`
  h1 {
    text-align: center;
    padding: 2rem 0;
    font-size: 3rem;
  }
  img {
    width: 100%;
    display: block;
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem 0;
  }
`
