const advanceHosting = {
  1: {
    title: 'Advance Plan 1',
    discountPercent: 50,
    priceINR: 60,
    priceUSD: 0.99,
    featureLabel: 'Most Popular',
    features: [
      '1 Domain',
      '5GB SSD Disk Space',
      'Unlimited Bandwidth',
      '50 Email Accounts',
      '50 MYSQL Databases',
      'Free SSL Certificate',
      '50 Sub-Domain'
    ],
    buttonLabel: 'Buy Now',
    buttonURL: 'https://my.bluegeekhosting.com/cart.php?a=add&pid=7'
  },
  2: {
    title: 'Advanced Plan 3',
    discountPercent: 50,
    priceINR: 180,
    priceUSD: 3,
    featureLabel: 'Most Popular',
    featured: true,
    features: [
      'Best Selling Plan',
      'Unlimited Domain',
      '15GB SSD Disk Space',
      'Unlimited Bandwidth',
      '250 Email Accounts',
      '250 MYSQL Databases',
      'Free SSL Certificate',
      '250 Sub-Domain'
    ],
    buttonLabel: 'Buy Now',
    buttonURL: 'https://my.bluegeekhosting.com/cart.php?a=add&pid=9',
    footerText: 'Best Value'
  },
  3: {
    title: 'Advance Plan 2',
    discountPercent: 50,
    priceINR: 120,
    priceUSD: 2,
    featureLabel: 'Most Popular',
    features: [
      '5 Domain',
      '10GB SSD Disk Space',
      'Unlimited Bandwidth',
      '100 Email Accounts',
      '100 MYSQL Databases',
      'Free SSL Certificate',
      '100 Sub-Domain'
    ],
    buttonLabel: 'Buy Now',
    buttonURL: 'https://my.bluegeekhosting.com/cart.php?a=add&pid=8'
  }
}
export default advanceHosting
