import React from 'react'
import NotFoundPage from '../components/404/404-page'
import NotFoundPricing from '../components/pricing/404-pricing/404-pricing'
import SEO from '../components/seo/seo'

const NotFound = () => (
  <>
    <SEO title="404 Not Found" />
    <NotFoundPage />
    <NotFoundPricing />
  </>
)

export default NotFound
